import { defineStore, createPinia } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    selectedAmenities:
      JSON.parse(localStorage.getItem("selectedAmenities")) || [],
    bookingDetails: JSON.parse(localStorage.getItem("bookingDetails")) || {},
    guestDetails: JSON.parse(localStorage.getItem("guestDetails")) || {},
  }),
  actions: {
    setSelectedAmenities(amenities) {
      this.selectedAmenities = amenities;
      localStorage.setItem("selectedAmenities", JSON.stringify(amenities));
    },
    setBookingDetails(booking) {
      this.bookingDetails = booking;
      localStorage.setItem("bookingDetails", JSON.stringify(booking));
    },
    setGuestDetails(guest) {
      this.guestDetails = guest;
      localStorage.setItem("guestDetails", JSON.stringify(guest));
    },
    resetStore() {
      this.selectedAmenities = [];
      this.bookingDetails = {};
      this.guestDetails = {};
      localStorage.removeItem("selectedAmenities");
      localStorage.removeItem("bookingDetails");
      localStorage.removeItem("guestDetails");
    },
  },
});

// Export the Pinia instance
const pinia = createPinia();
export default pinia;
