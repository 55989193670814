<template>
  <header class="header">
    <div class="title">BookNow</div>

    <button
      class="hamburger"
      @click="toggleMenu"
      :class="{ 'is-active': isMenuOpen }"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>

    <nav class="nav-menu" :class="{ 'is-open': isMenuOpen }">
      <ul class="nav-links">
        <li>
          <router-link to="/" @click="closeMenu">Home</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      // Prevent scrolling when menu is open
      document.body.style.overflow = this.isMenuOpen ? "hidden" : "";
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = "";
    },
  },
  beforeMount() {
    // Reset body overflow when component is destroyed
    document.body.style.overflow = "";
  },
  watch: {
    // Close menu on route change (optional)
    $route() {
      this.closeMenu();
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

/* Hamburger Button Styles */
.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 15px 35px;
  z-index: 1001;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background: #333;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.hamburger.is-active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

/* Navigation Menu Styles */
.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #666;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 1000;
  }

  .nav-menu.is-open {
    visibility: visible;
    opacity: 1;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 20px 0;
  }

  .nav-links a {
    font-size: 24px;
  }
}
</style>
