<template>
  <div class="root">
    <div class="bio">
      <div class="personal">
        <h1>Hello! My Name is</h1>
        <div class="name" @click="find()">Rohit Poduval</div>
        <h2>I work as a Product Manager at Oracle India</h2>
      </div>
      <div class="professional">
        <div class="whead">Work Experience</div>
        <Work />
      </div>
    </div>

    <!-- <div class="projects">
      <Cases />
    </div> -->
  </div>
</template>

<script>
import Work from "../components/Work.vue";

export default {
  name: "Home",
  components: { Work },
  props: {
    msg: String,
  },

  methods: {
    find() {
      this.$router.push("/find");
    },
  },
};
</script>

<style scoped>
body {
  margin: 0%;
  padding: 0%;
}

.root {
  margin: 0% 5%;
}

.bio {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.personal {
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.social {
  display: flex;
  justify-content: center;
}

.linkedin {
  padding: 0px 10px;
}

.professional {
  padding: 20px 0;
}

.whead {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.name {
  color: #42b983;
  font-size: 2em;
  font-weight: bold;
  display: block;
}

.projects {
  margin: 10px 0px;
}
</style>
