<template>
  <div class="footer">
    <button class="footer-button" @click="goToPrevStep">Previous</button>
    <button class="footer-button" style="display: none" @click="onNext">
      Next
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  methods: {
    goToPrevStep() {
      this.$emit("prev-step");
      window.location.reload();
    },
    onNext() {},
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #333;
  color: #fff;
}

.footer-button {
  background-color: #555;
  flex: 0 0 auto;
  color: #fff;
  border: none;
  padding: 15px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.footer-button:hover {
  background-color: #777;
}

.footer-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #aaa;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: row;
    gap: 30px;
  }
}
</style>
