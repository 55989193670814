import { createApp } from "vue";
import ElementPlus from "element-plus";
import { Back } from "@element-plus/icons-vue";
import { ElIcon } from "element-plus";
import "element-plus/theme-chalk/el-icon.css";
import "element-plus/dist/index.css";
import { createRouter, createWebHashHistory } from "vue-router";
import pinia, { useAppStore } from "./store.js";
import App from "./App.vue";
import About from "./views/About.vue";
import Att from "./components/Att.vue";
import MutualFunds from "./components/MutualFunds.vue";
import Booking from "./views/booking/Booking.vue";
import Confirmation from "./views/booking/Confirmation.vue";
import dayjs from "dayjs";
import axios from "axios";
import { bookingService } from "./services/booking.js";

const axiosInstance = axios.create({
  // Base URL for the API
  timeout: 10000, // Optional timeout setting
});

const routes = [
  { path: "/", component: About, name: "about" },
  { path: "/att", component: Att, name: "att" },
  { path: "/mf", component: MutualFunds, name: "MutualFunds" },
  { path: "/find", component: Booking, name: "Booking" },
  {
    path: "/booking/confirmation",
    component: Confirmation,
    name: "Confirmation",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode: "hash",
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

const app = createApp(App);
app.use(pinia);
app.config.globalProperties.$axios = axiosInstance;
app.use(router);
app.use(ElementPlus);
app.use(dayjs);
app.component("Back", Back);
app.component(ElIcon.name, ElIcon);
app.mount("#app");

app.config.globalProperties.$bookingService = bookingService;
app.config.globalProperties.$store = useAppStore();
