<template>
  <div class="main">
    <Header />
    <div class="header">
      <div class="head">Confirmation</div>
      <div class="sub">
        Your booking has been confirmed successfully. You wil receive an email
        soon with the booking details.
      </div>
    </div>
    <div class="container">
      <div class="section">
        <div
          class="title"
          style="display: flex; justify-content: space-between"
        >
          <div>{{ guestDetails.firstName }} {{ guestDetails.lastName }}</div>
          <div>#8932198419</div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>{{ guestDetails.email }}</div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>{{ guestDetails.phone }}</div>
        </div>
      </div>
      <div class="section">
        <div class="title">
          <div>Stay Details</div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>
            {{ bookingDetails.checkInDate }} - {{ bookingDetails.checkOutDate }}
          </div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>{{ bookingDetails.room.title }}</div>
        </div>
      </div>
      <div class="section">
        <div class="title">
          <div>Rate Details</div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>Best Available Rate</div>
        </div>
        <div class="subtitle">
          <div class="icon">
            <el-icon :size="20">
              <Back />
            </el-icon>
          </div>
          <div>{{ bookingDetails.room.price }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/booking/Header.vue";

export default {
  name: "Confirmation",
  components: { Header },
  data() {
    return {};
  },
  computed: {
    bookingDetails() {
      console.log("Rohit", this.$pinia._s.get("app").bookingDetails);
      return this.$pinia._s.get("app").bookingDetails;
    },
    guestDetails() {
      return this.$pinia._s.get("app").guestDetails;
    },
  },
};
</script>

<style scoped>
body {
  background-color: #ffffff;
  color: #000000;
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
}

.main {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
}

.header {
  padding: 20px;
}

.head {
  font-size: 20px;
  font-weight: bold;
}

.sub {
  font-size: 16px;
}

.container {
  padding: 0px;
  margin: 0 40px;
  border: 1px solid #eaeaea;
  height: auto;
}

.section {
  padding: 0px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
  border-radius: 4px;
}
.subtitle {
  display: flex;
  font-size: 16px;
  padding: 10px 10px 0px 10px;
  margin: 0px;
}
</style>
