<template>
  <div class="checkout">
    <div class="contact">
      <div class="header">
        <div class="arrow" @click="goToPrevStep()">
          <el-icon color="#fff" :size="30">
            <Back />
          </el-icon>
        </div>
        <div style="margin-left: 10px">Almost There!</div>
      </div>

      <div class="summary-mobile">
        <div class="room">{{ bookingDetails.room.title }}</div>
        <div class="guests">
          {{ bookingDetails.nights }} Night {{ bookingDetails.guests }} Guests
        </div>
        <div class="total">{{ bookingDetails.room.price }}</div>
      </div>

      <el-form :model="form" label-width="120px" class="guest-form">
        <el-form-item
          label-position="top"
          :rules="[
            {
              required: true,
              message: 'Please input first name',
              trigger: 'blur',
            },
          ]"
        >
          <div class="label">First Name</div>
          <el-input
            size="large"
            style="height: 48px"
            v-model="form.firstName"
            placeholder="Enter first name"
          />
        </el-form-item>

        <el-form-item
          label-position="top"
          :rules="[
            {
              required: true,
              message: 'Please input last name',
              trigger: 'blur',
            },
          ]"
        >
          <div class="label">Last Name</div>
          <el-input
            size="large"
            style="height: 48px"
            v-model="form.lastName"
            placeholder="Enter last name"
          />
        </el-form-item>

        <el-form-item
          label-position="top"
          :rules="[
            {
              required: true,
              message: 'Please input phone number',
              trigger: 'blur',
            },
          ]"
        >
          <div class="label">Phone Number</div>
          <el-input
            size="large"
            style="height: 48px"
            v-model="form.phone"
            placeholder="Enter Phone Number"
            class="input-with-select"
          >
            <template #prepend>
              <el-select
                v-model="form.code"
                placeholder="Select"
                style="width: 90px; height: 48px"
                size="large"
              >
                <el-option label="+91" value="+91" />
                <el-option label="+1" value="2" />
                <el-option label="+383" value="3" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item
          label-position="top"
          :rules="[
            {
              required: true,
              message: 'Please input email address',
              trigger: 'blur',
            },
          ]"
        >
          <div class="label">Email</div>
          <el-input
            size="large"
            style="height: 48px"
            v-model="form.email"
            placeholder="Enter email address"
          />
        </el-form-item>

        <el-form-item label-position="top">
          <div class="label">Special Remarks</div>
          <el-input
            size="large"
            style="height: 48px"
            type="textarea"
            v-model="form.remarks"
            placeholder="Enter special remarks"
            :rows="4"
          />
        </el-form-item>
      </el-form>
      <div class="payment">
        <div class="header"></div>
        <div class="pay-form">
          <form @submit.prevent="handleSubmit">
            <div ref="paymentElement"></div>
            <button
              class="pay"
              type="submit"
              v-if="clientSecret"
              :disabled="loading"
            >
              {{ loading ? "Processing..." : "Pay Now" }}
            </button>
          </form>
          <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
    <div class="summary" v-if="!Mobile">
      <div class="header">Summary</div>
      <div class="section">
        <div class="sec-header">Stay Details</div>
        <div class="content">Jan 27, 2025 - Jan 31,2025</div>
        <div class="content">Classic King Bed</div>
        <div class="content">Guests : 2 Adults</div>
      </div>
      <div class="section">
        <div class="sec-header">Rate Details</div>
        <div class="content">Total Room Rate : $123.50</div>
        <div class="content">Total Taxes : $12.50</div>
        <div class="content">Grand Total : $123.50</div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { useAppStore } from "@/store.js";

export default {
  name: "GuestForm",
  components: {},
  props: { Mobile: String },
  data() {
    return {
      clientSecret: null,
      form: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        remarks: null,
        code: "+91",
      },
    };
  },

  async mounted() {
    // Fetch the client secret from your backend
    const response = await fetch(
      "https://rohit-ux.vercel.app/api/create-payment-intent",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: 1000 }), // Example amount in cents ($10.00)
      }
    );

    const { clientSecret } = await response.json();
    this.clientSecret = clientSecret;

    // Initialize Stripe
    this.stripe = await loadStripe(
      "pk_test_51QUQQ5Cc9zRMo6lbrAsXj4zmJEqABNWpoRym6xu2HMvC2BGsT073BRxYr7S0w0UDaPCwT3vze8rVECCxmj63RFpI00ILu4PDc5"
    ); // Replace with your Stripe public key
    this.elements = this.stripe.elements({ clientSecret });

    // Create the Payment Element and mount it
    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount(this.$refs.paymentElement);
  },

  methods: {
    submitForm() {
      console.log("success");
    },
    goToPrevStep() {
      this.$emit("prev-step");
      window.location.reload();
    },

    async handleSubmit() {
      this.loading = true;
      this.errorMessage = "";
      const isLocalhost = window.location.hostname === "localhost";
      const baseUrl = isLocalhost
        ? "http://localhost:5173"
        : "https://uxrohit.com";

      const store = useAppStore();
      store.setGuestDetails(this.form);

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${baseUrl}/#/booking/confirmation`,
        },
      });

      if (error) {
        this.errorMessage = error.message;
        this.loading = false;
      } else {
        this.submitForm();
      }
    },
  },

  computed: {
    store() {
      return useAppStore(); // Access the Pinia store
    },

    bookingDetails() {
      console.log("Rohit", this.$pinia._s.get("app").bookingDetails);
      return this.$pinia._s.get("app").bookingDetails; // Access the state directly
    },
    guestDetails() {
      return this.$pinia._s.get("app").guestDetails; // Access the state directly
    },
  },
};
</script>

<style scoped>
.checkout {
  display: flex;
  margin: 0 auto;
  padding: 70px 20px 70px 20px;
}

.contact {
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media (max-width: 768px) {
  .contact {
    width: 100%;
  }
}

.arrow {
  border: 1px solid #eaeaea;
  padding: 10px 8px 0px 8px;
  border-radius: 100px;
  background-color: black;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-weight: bold;
}

.pay-header {
  display: flex;
  padding: 10px 0;
  font-weight: bold;
}

.guest-form {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
}

.payment {
  border-radius: 4px;
  margin-bottom: 30px;
}

.pay-form {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 4px;
}

#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.pay {
  margin: 20px 0;
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.summary-mobile {
  display: flex;
  background-color: #eaeaea;
  font-size: 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;
}

.room {
  padding: 10px;
  border-right: 1px solid #909090;
}

.guests {
  padding: 10px;
  border-right: 1px solid #909090;
}

.total {
  padding: 10px;
}

@media (max-width: 768px) {
  .summary {
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.section {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.sec-header {
  border: 1px solid #eaeaea;
  padding: 5px;
  text-align: left;
  background: #eaeaea;
  font-weight: bold;
}

.content {
  padding: 5px;
  font-weight: 500;
  margin: 0;
}
</style>
