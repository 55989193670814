<template>
  <div class="filter-screen" v-if="isVisible">
    <div class="filter-header">
      <div>Filters</div>
      <button @click="$emit('close')" class="close-btn">X</button>
    </div>
    <div class="filter-content">
      <div class="amenities">
        <div class="header">Amenites</div>

        <div class="tags" style="padding: 5px">
          <el-check-tag-group>
            <el-check-tag
              v-for="(amenity, index) in selectedAmenitiesFull"
              :key="index"
              :value="amenity.code"
              type="primary"
              @change="unToggle(amenity)"
              checked
              style="
                padding: 20px;
                margin: 5px;
                color: #fff;
                background-color: #000;
                border-radius: 30px;
              "
            >
              {{ amenity.name }}
            </el-check-tag>
          </el-check-tag-group>
        </div>

        <div class="tags" style="padding: 5px">
          <el-tag-group>
            <el-check-tag
              v-for="(amenity, index) in newamenities"
              :key="index"
              :value="amenity.code"
              :type="
                selectedAmenities.includes(amenity.code) ? 'primary' : 'default'
              "
              effect="light"
              @change="toggle(amenity)"
              round
              style="padding: 20px; margin: 5px; border-radius: 30px"
            >
              {{ amenity.name }}
            </el-check-tag>
          </el-tag-group>
        </div>
      </div>
      <div class="filter-footer">
        <button @click="resetFilters" class="reset-btn">Reset</button>
        <button @click="$emit('close')" class="reset-btn">Close</button>
        <button @click="applyFilters" class="apply-btn">Apply Filters</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store.js";

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    amenities: Array,
  },
  data() {
    return {
      keyword: "",
      checked: null,
      selectedAmenities: [],
      selectedAmenitiesFull: [],
      newamenities: [...this.amenities],
    };
  },

  computed: {
    store() {
      return useAppStore(); // Access the Pinia store
    },
  },

  methods: {
    applyFilters() {
      const store = useAppStore();
      store.setSelectedAmenities(this.selectedAmenities); // Call the action to update state
      this.$emit("close");
    },

    toggle(amenity) {
      this.selectedAmenities.push(amenity.code);
      this.selectedAmenitiesFull.push(amenity);
      let a = this.amenities.findIndex((x) => x.code === amenity.code);
      this.newamenities.splice(a, 1);
    },

    unToggle(amenity) {
      let a = this.amenities.findIndex((x) => x.code === amenity.code);
      this.selectedAmenitiesFull.splice(a, 1);
      this.selectedAmenities.splice(a, 1);
      this.newamenities.splice(a, 0, amenity);
    },

    resetFilters() {
      this.newamenities = [...this.amenities];
      this.selectedAmenities = [];
      this.selectedAmenitiesFull = [];
      const store = useAppStore();
      store.setSelectedAmenities(this.selectedAmenities);
    },
  },
};
</script>

<style scoped>
/* Fullscreen Filter Screen */
.filter-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/* Header Section */
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
}

.header {
  font-size: 16px;
  font-weight: bold;
}

.filter-footer {
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Content Section */
.filter-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Apply Button */
.apply-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  height: 48px;
}

.reset-btn {
  padding: 10px 10px;
  height: 48px;
  font-size: 16px;
  width: 20%;
  border: 1px solid #eaeaea;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
