<template>
  <div class="main">
    <div class="content">
      <Header />
      <Shop v-if="step === 1" @next-step="nextStep" :Mobile="isMobile" />
      <Checkout
        v-if="step === 2"
        @next-step="nextStep"
        :Mobile="isMobile"
        @prev-step="goBack"
      />
    </div>
    <footer class="footer" v-if="step > 4 && showBottomComponent">
      <Footer @prev-step="goBack" :step="step" />
    </footer>
  </div>
</template>

<script>
import Shop from "@/views/booking/Shop.vue";
import Checkout from "@/views/booking/Checkout.vue";
import Header from "@/views/booking/Header.vue";
import Footer from "@/views/booking/Footer.vue";

export default {
  name: "Booking",
  components: { Shop, Checkout, Header, Footer },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    window.addEventListener("scroll", this.checkScrollPosition);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
    window.removeEventListener("scroll", this.checkScrollPosition);
  },

  data() {
    return {
      step: 1,
      isMobile: false,
      isAtTop: true,
      showBottomComponent: false,
    };
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 800;
    },

    nextStep() {
      if (this.step < 3) {
        this.step++;
      }
    },

    goBack() {
      if (this.step > 1) {
        this.step--;
        this.$router.replace({
          path: this.$route.path,
          query: this.$route.query,
        });
      }
    },

    checkScrollPosition() {
      const scrollTop = window.scrollY; // Current vertical position
      const windowHeight = window.innerHeight; // Viewport height
      const documentHeight = document.documentElement.scrollHeight; // Full page height

      this.showBottomComponent =
        scrollTop + windowHeight >= documentHeight - 10; // Adjust for a small threshold
    },
  },
};
</script>

<style>
body {
  background-color: #ffffff;
  color: #000000;
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
}
.main {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin: 0px;
  padding: 0px;
}

.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
