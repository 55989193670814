import axios from "axios";

const baseURL = "https://rohit-ux.vercel.app/api";
//const baseURL = "http://127.0.0.1:5001/rohit211195-portfolio/us-central1";

export const bookingService = async (params = {}) => {
  const response = await axios.get(`${baseURL}/data`, {
    params: params, // Pass the query parameters here
  });
  return response.data; // Return the data from the response
};
